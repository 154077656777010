// navigation icons
import React, { FC, useEffect } from 'react';
import { ProtectedRoute } from '@wings-shared/layout';
import { IBaseModuleProps, VIEW_MODE } from '@wings/shared';
import { Routes, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  AirportGeneralInformation,
  AirportHoursDetails,
  FlightPlanInformation,
  OperationalInformation,
  Ownership,
  AirportRunway,
  AirportRunwayDetails,
  AirportFrequency,
  TimezoneInformation,
  CustomDetails,
  AirportSecurity,
  VendorLocations,
  AirportRunwayClosure
} from '../../Components';
import { AirportStore, airportSidebarOptions, AirportModel, useAirportModuleSecurity } from '../../../Shared';
import { finalize, takeUntil } from 'rxjs/operators';
import { useNavigate, useParams } from 'react-router';
import { UIStore, IAPIGridRequest, ViewPermission } from '@wings-shared/core';
import { useUnsubscribe } from '@wings-shared/hooks';

interface Props extends Partial<IBaseModuleProps> {
  airportStore?: AirportStore;
}

const UpsertAirport: FC<Props> = props => {
  const params = useParams();
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();
  const _airportStore = props.airportStore as AirportStore;
  const airportModuleSecurity = useAirportModuleSecurity();

  const airportBasePath = (): string => {
    const { airportId, icao, viewMode } = params;
    if (airportId) {
      return icao ? `airports/upsert/${airportId}/${icao}/${viewMode}` : `airports/upsert/${airportId}/${viewMode}`;
    }
    return 'airports/upsert/new';
  };

  /* istanbul ignore next */
  useEffect(() => {
    props.sidebarStore?.setNavLinks(airportSidebarOptions(false, !Boolean(params.airportId)), airportBasePath());
    loadAirport();
    return () => {
      if (params?.airportId) {
        _airportStore.selectedAirport = null;
      }
    };
  }, []);

  /* istanbul ignore next */
  const loadAirport = (): void => {
    if (!params.airportId || _airportStore.selectedAirport?.id) {
      unsubscribe.setHasLoaded(true);
      return;
    }
    const request: IAPIGridRequest = {
      pageNumber: 1,
      pageSize: 10,
      filterCollection: JSON.stringify([{ propertyName: 'AirportId', propertyValue: params.airportId }]),
    };
    UIStore.setPageLoader(true);
    _airportStore
      .getAirports(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(response => {
        // restrict edit view for inactive airports 62982
        if (!response.results[0]?.isActive) {
          params.navigate &&
            navigate(`/airports/upsert/${params?.airportId}/${params?.icao}/${VIEW_MODE.DETAILS.toLocaleLowerCase()}`);
        }
        _airportStore.selectedAirport = response.results[0];
        unsubscribe.setHasLoaded(true);
      });
  };

  return (
    <ViewPermission hasPermission={unsubscribe.hasLoaded}>
      <Routes>
        <Route index element={<AirportGeneralInformation />} />
        <Route path="airport-hours" element={<AirportHoursDetails key={'airport-hours'} />} />
        <Route path="operational-information" element={<OperationalInformation />} />
        <Route path="flight-plan-information" element={<FlightPlanInformation />} />
        <Route path="ownership" element={<Ownership />} />
        <Route path="runway" element={<AirportRunway />} />
        <Route path="runwayClosure" element={<AirportRunwayClosure />} />
        <Route
          path="runway/new"
          key="new"
          element={
            <ProtectedRoute
              element={<AirportRunwayDetails viewMode={params.viewMode as VIEW_MODE} key={'new-runway'} />}
              hasPermission={airportModuleSecurity.isEditable}
              redirectPath={'/airports'}
            />
          }
        />
        <Route
          path="runway/:runwayId/:runwayViewMode"
          key="details-runway"
          element={<AirportRunwayDetails viewMode={params.viewMode as VIEW_MODE} key={'runway-details'} />}
        />
        <Route path="airport-frequencies" element={<AirportFrequency />} />
        <Route
          path="timezone-information"
          element={<TimezoneInformation airport={_airportStore.selectedAirport as AirportModel} />}
        />
        <Route path="airport-security" element={<AirportSecurity />} />
        <Route path="vendor-locations" element={<VendorLocations />} />
        <Route path="custom-detail/*" element={<CustomDetails />} />
      </Routes>
    </ViewPermission>
  );
};

export default inject('sidebarStore', 'airportStore')(observer(UpsertAirport));
